import React from 'react';
import { useWindowScroll } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { Box, Container, Group, Image, Text } from '@mantine/core';
import Link from 'next/link';
import { SPACING_LG, SPACING_MD, SPACING_SM } from '@/config/theme';

const Header = ({ isMobile, fixed, onLogoClick }) => {
  const router = useRouter();
  const [scroll] = useWindowScroll();

  return (
    <>
      {(router.pathname !== '/' || scroll.y >= 200 || fixed) && (
        <Group
          pl={isMobile ? SPACING_SM : SPACING_LG}
          pr={SPACING_LG}
          sx={{
            zIndex: 3,
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            height: isMobile ? 42 : 50,
            background: `rgba(255,255,255,${0.85})`,
            backdropFilter: 'blur(3px)',
            userSelect: 'none',
          }}
        >
          <Container sx={{ width: '100%' }}>
            <header>
              <Group
                sx={{
                  justifyContent: 'center',
                  position: 'relative',
                  alignItems: 'center',
                }}
              >
                <Link
                  href='/'
                  style={{ position: 'absolute', left: 0 }}
                  onClick={() => {
                    if (onLogoClick) onLogoClick();
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: -20,
                      height: isMobile ? 42 : 50,
                      paddingLeft: 20,
                      paddingRight: 20,
                      background:
                        'radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',

                      // '&:hover': {
                      // 	background:
                      // 		'radial-gradient(circle, rgba(244,227,233,1) 50%, rgba(255,255,255,0) 100%)',
                      // },
                    }}
                  >
                    <Image
                      src='/images/logo.svg'
                      alt="L'Épître"
                      width={118}
                      height={28}
                    />
                  </Box>
                </Link>
                {!isMobile && (
                  <Box
                    sx={{
                      height: isMobile ? 42 : 50,
                      background:
                        'radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Text sx={{ fontFamily: 'bagnard', fontSize: 18 }}>
                      Revue de relève littéraire
                    </Text>
                  </Box>
                )}

                <Box />
              </Group>
            </header>
          </Container>
        </Group>
      )}
    </>
  );
};

export default Header;
