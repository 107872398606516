import React from 'react';
import {
  ActionIcon,
  Anchor,
  Box,
  Group,
  NavLink,
  Popover,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import Section from './_generic/Section';
import {
  COLOR_SECONDARY,
  FONT_COLOR_DEFAULT,
  FONT_FAMILY_PRIMARY,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
} from '@/config/theme';
import menu from '@/data/menu';
import Link from 'next/link';
import { IconBrandFacebook, IconBrandInstagram } from '@tabler/icons-react';
import Image from 'next/image';
import SocialIcons from './SocialIcons';

const THIS_YEAR = new Date().getFullYear();

const Footer = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');

  return (
    <footer>
      <Section color='primary'>
        <SimpleGrid
          cols={isMobile ? 1 : 3}
          spacing={isMobile ? 48 : SPACING_MD}
          sx={{ alignItems: 'center' }}
        >
          <Box
            sx={{
              color: '#fff',
              fontFamily: FONT_FAMILY_PRIMARY,
              fontSize: 22,
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
            }}
          >
            {menu.map((menuItem) => (
              <Link key={menuItem.href} href={menuItem.href}>
                <NavLink
                  label={menuItem.label}
                  styles={{
                    label: {
                      fontSize: isMobile ? 22 : 22,
                    },
                  }}
                />
              </Link>
            ))}
          </Box>
          <Stack align='center'>
            <Text sx={{ color: '#fff' }}>
              Retrouvez-nous sur les réseaux sociaux
            </Text>
            <SocialIcons />
          </Stack>
          <Stack align={isMobile ? 'center' : 'flex-end'}>
            <Stack
              justify='flex-start'
              sx={{ width: 200 }}
              spacing={SPACING_XS}
            >
              <Text sx={{ color: '#fff' }}>L'Épître, © 2012-{THIS_YEAR}</Text>
              <Text sx={{ color: '#fff' }}>
                Conception web par{' '}
                {/* <Anchor
                  target='_blank'
                  href='http://innovation-it.ch'
                  style={{
                    textDecoration: 'underline',
                    color: COLOR_SECONDARY,
                  }}
                > */}
                Jonathan Zappalà
                {/* </Anchor> */}
              </Text>
              <Text sx={{ color: '#fff' }}>
                Conception graphique par{' '}
                <Anchor
                  target='_blank'
                  href='http://vanessacojocaru.com'
                  style={{
                    textDecoration: 'underline',
                    color: COLOR_SECONDARY,
                  }}
                >
                  Vanessa Cojocaru
                </Anchor>
              </Text>
              <Text sx={{ color: '#fff' }}>
                Tous droits réservés.
                <br />
                <Popover
                  width={isMobile ? '100vw' : '40vw'}
                  position='top'
                  withArrow
                  shadow='md'
                >
                  <Popover.Target>
                    <span
                      style={{
                        textDecoration: 'underline',
                        color: COLOR_SECONDARY,
                        cursor: 'pointer',
                      }}
                    >
                      Mentions légales
                    </span>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text
                      sx={{
                        color: FONT_COLOR_DEFAULT,
                        textAlign: 'justify',
                        lineHeight: 1.25,
                      }}
                    >
                      <p>
                        Le site de la revue littéraire L’Épître est une
                        plateforme gratuite. Chaque texte est la propriété de
                        son auteur.e et est protégé contre la copie ou le
                        plagiat en vertu du droit suisse. Toute copie partielle
                        ou totale d'un des textes sans l'accord préalable de son
                        auteur.e constitue une violation de la Loi fédérale du 9
                        octobre 1992 sur le droit d’auteur et les droits voisins
                        (LDA).
                      </p>

                      <p>
                        Par ailleurs, L’Épître ne saurait être tenu responsable
                        des différents points de vue exprimés par les auteur.e.s
                        des textes. Si vous souhaitez contacter l’auteur.e d'un
                        texte, demandez-nous par mail en motivant votre demande.
                      </p>

                      <p>
                        Le logo de la revue ainsi que les textes ne sont pas
                        sujets à reproduction en vertu de la Loi, nous nous
                        réservons le droit d'engager des poursuites en cas
                        d'abus
                      </p>

                      <p>info@lepitre.ch</p>
                    </Text>
                  </Popover.Dropdown>
                </Popover>
              </Text>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Section>
    </footer>
  );
};

export default Footer;
