import { Container, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import {
  COLOR_SECONDARY,
  COLOR_GREY_DARK,
  COLOR_PRIMARY,
  SPACING_MD,
  SPACING_SM,
  FONT_COLOR_DEFAULT,
} from '@/config/theme';

const colors = {
  primary: COLOR_PRIMARY,
  secondary: COLOR_SECONDARY,
  white: '#fff',
  // dark: '#fff',
  dark: COLOR_GREY_DARK,
};

const fontColors = {
  primary: '#fff',
  secondary: FONT_COLOR_DEFAULT,
  white: FONT_COLOR_DEFAULT,
  dark: colors.secondary,
  // dark: '#fff',
};

const Section = ({ title, color, disableContainer, style, children }) => {
  const isMobile = useMediaQuery('(max-width: 900px)');

  return (
    <section
      style={{
        background: colors[color],
        padding: isMobile
          ? `${SPACING_MD}px ${SPACING_SM}px ${SPACING_MD}px ${SPACING_SM}px`
          : SPACING_MD,
        ...style,
      }}
    >
      {!disableContainer ? (
        <Container
          sx={{
            width: '100%',
          }}
        >
          {title && (
            <Title
              order={2}
              sx={{
                textAlign: isMobile ? 'left' : 'left',
                color: fontColors[color],
              }}
            >
              {title.toUpperCase()}
            </Title>
          )}
          {children}
        </Container>
      ) : (
        children
      )}
    </section>
  );
};

Section.defaultStyles = {
  style: {},
};

export default Section;
