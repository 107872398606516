import React, { useState } from 'react';
import {
  Box,
  Burger,
  Center,
  Container,
  NavLink,
  Transition,
  UnstyledButton,
} from '@mantine/core';
import { animated, useSpring, easings } from 'react-spring';

import {
  COLOR_PRIMARY,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
} from '@/config/theme';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useWindowScroll } from '@mantine/hooks';
import menu from '@/data/menu';
import Header from './Header';
import SocialIcons from './SocialIcons';

const RootMenu = ({ isMobile }) => {
  const [open, setOpen] = useState(false);

  const springAnimation = useSpring({
    // config: { velocity, mass: 1, tension: 100, friction: 3, clamp: true,  },
    config: { duration: 300, easing: easings.easeInOutQuint },
    opacity: open ? 1 : 0,
    x: open ? '0%' : '100%',
  });

  return (
    <>
      <Box
        onClick={() => setOpen((open) => !open)}
        sx={{
          zIndex: 4,
          position: 'fixed',
          top: 0,
          right: 0,
          padding: isMobile ? SPACING_XS / 2 : SPACING_XS,
          background: COLOR_PRIMARY,
        }}
      >
        <Burger
          id='main_menu'
          aria-label='main menu'
          opened={open}
          color='#fff'
        />
      </Box>

      <animated.div
        style={{
          position: 'fixed',
          zIndex: 3,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          padding: SPACING_MD,
          background: COLOR_PRIMARY,
          // opacity: springAnimation.opacity,
          x: springAnimation.x,
        }}
      >
        <Center sx={{ height: '100vh' }}>
          <Container>
            <nav>
              {menu.map((menuItem) => (
                <Link
                  key={menuItem.href}
                  href={menuItem.href}
                  onClick={() => setOpen(false)}
                >
                  <NavLink
                    label={menuItem.label}
                    styles={{ label: { fontSize: isMobile ? 28 : 48 } }}
                  />
                </Link>
              ))}
              <Box mt={SPACING_MD}>
                <SocialIcons />
              </Box>
            </nav>
          </Container>
        </Center>
      </animated.div>

      {open && (
        <Header
          fixed={true}
          isMobile={isMobile}
          onLogoClick={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default RootMenu;
